import {
  VStack,
  Image,
  Heading,
  List,
  ListItem,
  ListIcon,
  Box,
  HStack,
  Flex,
} from "@chakra-ui/react";
import { useTranslation } from "~/libs/i18n";
import { CheckCircleIcon } from "@chakra-ui/icons";
import CustomImage from "~/components/CustomImage";

const ListContent = ({ icon, text }: { icon: any; text: string }) => {
  return (
    <ListItem>
      <Flex alignItems={"center"}>
        <ListIcon as={CheckCircleIcon} color="brand.timable-yellow" />
        {text}
      </Flex>
    </ListItem>
  );
};

const LoginMessage = () => {
  const t = useTranslation();

  return (
    <VStack alignItems={"flex-start"} w="full">
      <HStack mb={2}>
        <Heading as="h5">{t("member.benefitTitle")}</Heading>
        <CustomImage
          src={"/assets/images/member/image_01.svg"}
          display={{ base: "none", xl: "block" }}
        />
      </HStack>
      <List spacing={3}>
        <ListContent icon={CheckCircleIcon} text={t("member.benefit01")} />
        <ListContent icon={CheckCircleIcon} text={t("member.benefit02")} />
        <ListContent icon={CheckCircleIcon} text={t("member.benefit03")} />
      </List>
      <Box>
        <CustomImage
          src={"/assets/images/member/register_mv.svg"}
          alt="register_main_visual"
        />
      </Box>
    </VStack>
  );
};

export default LoginMessage;
